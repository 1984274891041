export default [
  {
    title: 'Journals',
    icon: 'HomeIcon',
    route: 'jS',
  },
  {
    title: 'News',
    icon: 'HomeIcon',
    route: 'news',
  },
  {
    title: 'Archives',
    icon: 'HomeIcon',
    route: 'a',
  },
  {
    title: 'For Authors',
    icon: 'HomeIcon',
    route: 'fa',
  },
  {
    title: 'contact Us',
    icon: 'HomeIcon',
    route: 'cu',
  },
  {
    title: 'FAQ',
    icon: 'HomeIcon',
    route: 'faq',
  },
  {
    title: 'About Us',
    icon: 'HomeIcon',
    route: 'home',
  },
]
