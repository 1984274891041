<template>
  <div class="navbar-header d-xl-block d-s-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link 
        @click.prevent="goBack"
          class="navbar-brand"
        >
          <!-- <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
            />
          </span> -->
          <h1 class="brand-text mb-0">
            {{title}}
          </h1>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  watch: {
    "$i18n.locale": function (val) {
      this.checkTitle()
    },
  },
  data() {
    return {
      title: "",
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  mounted(){
    this.checkTitle()
  },
  methods:{
    checkTitle(){
      if(this.$i18n.locale=="ar"){
        this.title = "مجلات النسور العلمية" 
      }else{
        this.title = "Al-Nisour Scientific Journals"
      }
    },
    goBack(){
      this.reset()
      this.$router.push("/");
    },
    reset() {
      this.$store.commit("reset");
    },
  }
}
</script>

<style>

</style>
